<template>
  <div class="canvas-wrapper">
    <div class="hero-banner">
      <h1 class="firstname">Guillaume</h1>
      <h1 class="lastname">LAGOUY</h1>
      <p>French creative developer</p>
      <div class="btn-container">
        <div class="btn-projects">Discover my projects</div>
      </div>
    </div>
    <canvas class="responsive" id="matter"></canvas>
    <canvas class="responsive" id="grid"></canvas>
  </div>
</template>

<script>
import MatterWorld from '../core/matter/MatterWorld'

export default {
  name: 'ResponsiveCanvas',
  data() {
    return {
      formats: [
        { vertical: 12, horizontal: 6, name: 'landscape' },
        { vertical: 6, horizontal: 12, name: 'portrait' },
      ],
      tileSize: null,
      currentFormat: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.resizeCanvas();

      window.addEventListener('resize', () => this.onResize());
    })
  },
  methods: {
    resizeCanvas() {
      const wrapper = document.querySelector('.canvas-wrapper');
      const wrapperSize = {
        width: wrapper.clientWidth,
        height: wrapper.clientHeight,
      }
      this.currentFormat = this.getFormat(wrapperSize.width, wrapperSize.height);
      this.tileSize = this.currentFormat.name === 'landscape'
          ? wrapperSize.width / this.currentFormat.vertical
          : wrapperSize.height / this.currentFormat.horizontal;

      const canvasElts = document.getElementsByClassName('responsive');
      canvasElts.forEach(canvas => {
        canvas.width = this.tileSize * this.currentFormat.vertical;
        canvas.height = this.tileSize * this.currentFormat.horizontal;
      })

      this.drawGrid();
      this.initMatter();
      this.displayHeroBanner();
    },
    /**
     *
     * @param {Number} width
     * @param {Number} height
     * @returns Object
     */
    getFormat(width, height) {
      return width > height
          ? this.formats[0]
          : this.formats[1]
    },
    drawGrid() {
      const canvas = document.getElementById('grid');
      const ctx = canvas.getContext('2d');

      ctx.fillStyle = 'transparent';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // vertical lines
      for (let v = 0; v < canvas.width; v += this.tileSize) {
        ctx.beginPath();
        ctx.moveTo(v, 0);
        ctx.lineTo(v, canvas.height);
        ctx.stroke();
      }
      // horizontal lines
      for (let h = 0; h < canvas.height; h += this.tileSize) {
        ctx.beginPath();
        ctx.moveTo(0, h);
        ctx.lineTo(canvas.width, h);
        ctx.stroke();
      }
    },
    onResize(){
      this.matterWorld.clear();
      this.resizeCanvas();
    },
    initMatter() {
      const matterCanvas = document.getElementById('matter');
      this.matterWorld = new MatterWorld(matterCanvas, this.tileSize, this.currentFormat);
      this.matterWorld.init();
    },
    displayHeroBanner() {
      const matterCanvas = document.getElementById('matter');
      const pos = matterCanvas.getBoundingClientRect();

      const homeContainer = document.querySelector(".home-container");
      const homeContainerStyle = window.getComputedStyle(homeContainer);
      const padding = {
        top : Number(homeContainerStyle.paddingTop.replace(/px/g,'')),
        left : Number(homeContainerStyle.paddingLeft.replace(/px/g,'')),
      }

      const heroSettings = {
        x : this.currentFormat.name === "landscape" ? 3 : 0,
        y : this.currentFormat.name === "landscape" ? 2 : 3,
        width: this.currentFormat.name === "landscape" ? 7 : 4,
        height: this.currentFormat.name === "landscape" ? 4 : 7,
      }

      const heroBanner = document.querySelector(".hero-banner");
      heroBanner.style.left = pos.x + this.tileSize * heroSettings.x - padding.left + "px";
      heroBanner.style.top = pos.y + this.tileSize * heroSettings.y - padding.top + "px";
      heroBanner.style.width = this.tileSize * heroSettings.width + "px";
      heroBanner.style.height = this.tileSize * heroSettings.height + "px";
    }
  }
}
</script>

<style scoped>
.canvas-wrapper {
  height: 100%;
  position: relative;
  z-index: 10;
}

.responsive {
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}

.hero-banner {
  position: absolute;
  z-index: 1;
  color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.hero-banner h1 {
  font-family: "ClashGrotesk-Semibold", sans-serif;
}
.hero-banner .firstname {
  font-size: 10vw;
  line-height: 0.8;
}
.hero-banner .lastname {
  font-size: 9vw;
  letter-spacing: 0.002em;
  line-height: 1.2;
}
.hero-banner p {
  font-family: "ClashGrotesk-Medium", sans-serif;
  font-size: 2.5vw;
  border-top: solid 0.2rem white;
  padding-top: 0.5rem;
}

.btn-container {
  height: 80%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.btn-projects {
  font-family: "ClashGrotesk-Regular", sans-serif;
  background: white;
  color: #121212;
  width: 40%;
  font-size: 1.8vw;
  text-align: center;
  padding: 0.5rem 1.2rem;

  /*TODO : To delete */
  display: none;
}
@media (orientation: portrait) {
  .hero-banner .firstname, .hero-banner .lastname {
    margin-top: 1.4rem;
  }
  .hero-banner p {
    margin-top: 1.4rem;
    padding-top: 1rem;
    font-size: 5vw;
  }
  .btn-projects {
    font-size: initial;
    width: 100%;
  }
}

#matter {
  background: white;

}
#grid {
  background: transparent;
  display: none;
}
</style>
