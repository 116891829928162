export const landscapeMap = [
  ["."    , "B11" , "SL1I" , "B11CD", "SR6I", ".", ".", ".", ".", ".", "B21" , "."],
  ["SL1"  , "SR1I", "."    , "SL8"  , ".", ".", ".", ".", ".", ".", "."   , "SR1I"],
  ["SL1I" , "SR1" , "."    , "B74"  , ".", ".", ".", ".", ".", ".", "SL1I", "SR1" ],
  ["SL1"  , "SR1I", "."    , "."    , ".", ".", ".", ".", ".", ".", "SL1" , "SR1I"],
  ["SL1I" , "SR1" , "."    , "."    , ".", ".", ".", ".", ".", ".", "SL1I", "SR1" ],
  ["SL1"  , "."   , "B11CU", "."    , ".", ".", ".", ".", ".", ".", "SL1" , "."   ],
];

export const portraitMap = [
  ["."  , "SR1I", "B21" , "."   , "SL1I", "SR1I"],
  ["."  , "."   , "SR1I", "SL1I", "."   , "."   ],
  ["SL5", "."   , "."   , "."   , "."   , "."   ],
  ["B47", "."   , "."   , "."   , "SL1I", "."   ],
  ["."  , "."   , "."   , "."   , "."   , "SR1" ],
  ["."  , "."   , "."   , "."   , "SL1" , "SR1I"],
  ["."  , "."   , "."   , "."   , "SL1I", "SR1" ],
  ["."  , "."   , "."   , "."   , "SL1" , "SR1I"],
  ["."  , "."   , "."   , "."   , "SL1I", "SR1" ],
  ["."  , "."   , "."   , "."   , "SL1" , "SR1I"],
  ["."  , "."   , "."   , "."   , "."   , "SR1" ],
  ["."  , "SR4" , "."   , "."   , "."   , "B11" ]
];

/**
 * It's a slope if the code begin by S,
 * the second letter indicate the direction,
 * the third character is size and
 * if the code ending by I the slope is flip horizontaly.
 *
 * It's a box if the code begin by B,
 * the second and the third character is the width and the height of the square.
 *
 * @param {String} code
 * @returns {Object}
 */
export function codeToBlock(code, row, column){
  const blockDico = {
    S: "slope",
    B: "box",
    L: "left",
    R: "right"
  }

  const sCode = code.split("");
  const block = {
    type: blockDico[sCode[0]],
    coord: {
      x: column,
      y: row
    }
  };
  if (block.type === 'slope') {
    block.direction = blockDico[sCode[1]];
    block.size = sCode[2];
    block.inverted = !!sCode[3];
  } else if (block.type === 'box') {
    block.width = sCode[1];
    block.height = sCode[2];
    block.collider = !!sCode[3];
    if(block.collider) block.gravity = sCode[4] === "U" ? "up" : "down";
  }
  return block;
}
