<template>
  <div class="home-container">
    <responsive-canvas />
  </div>
</template>

<script>
import ResponsiveCanvas from '../components/ResponsiveCanvas'

export default {
  name: 'Home',
  components: { ResponsiveCanvas }
}
</script>

<style scoped>
.home-container {
  height: 100%;
  padding: 2rem;
  background: #121212;
}
@media only screen and (max-width: 600px) {
  .home-container {
    padding: 8% 2rem;
  }
}
</style>
