<template>
  <home />
</template>

<script>
import Home from './pages/Home';

export default {
  name: 'App',
  components: { Home },
  mounted () {
    /* Hack for setting #app in full height on mobile */
    const vh = window.innerHeight * 0.01;
    document.body.querySelector('#app').style.setProperty('--vh', `${vh}px`);
  }
}
</script>

<style>
@font-face {
  font-family: 'ClashGrotesk-Extralight';
  src: url('./fonts/ClashGrotesk/ClashGrotesk-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'ClashGrotesk-Light';
  src: url('./fonts/ClashGrotesk/ClashGrotesk-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'ClashGrotesk-Regular';
  src: url('./fonts/ClashGrotesk/ClashGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'ClashGrotesk-Medium';
  src: url('./fonts/ClashGrotesk/ClashGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'ClashGrotesk-Semibold';
  src: url('./fonts/ClashGrotesk/ClashGrotesk-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: 'ClashGrotesk-Bold';
  src: url('./fonts/ClashGrotesk/ClashGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

* {
  padding: 0; margin: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;

  height: 100vh;
  /* Hack for setting #app in full height on mobile */
  height: calc(var(--vh, 1vh) * 100);
}
</style>
