import * as Matter from 'matter-js';
import { landscapeMap, portraitMap, codeToBlock } from '../../utils/map';
import Box from './Box';
import Slope from './Slope'

export default class MatterWorld {
  /**
   * @param {HTMLCanvasElement} canvas
   * @param {Number} tileSize
   * @param {Object} format
   * @constructor
   */
  constructor (canvas, tileSize, format) {
    this.canvas = canvas;
    this.tileSize = tileSize;
    this.format = format;

    this.Engine = Matter.Engine;
    this.Render = Matter.Render;
    this.Runner = Matter.Runner;
    this.Bodies = Matter.Bodies;
    this.Body = Matter.Body;
    this.Composite = Matter.Composite;
    this.Events = Matter.Events;
    this.Grid = Matter.Grid;

    this.colliders = []
  }

  init() {
    this.engine = this.Engine.create();
    this.render = this.Render.create({
      canvas: this.canvas,
      engine: this.engine,
      options: {
        width: this.canvas.width,
        height: this.canvas.height,
        background: '#ffffff',
        wireframes: false,
        showDebug: false,
      }
    });
    this.Render.setPixelRatio(this.render, 'auto');

    this.Grid.create();
    this.Render.run(this.render);
    this.runner = this.Runner.create();
    this.Runner.run(this.runner, this.engine);

    // Build Map
    const blocks = this.loadMap(this.format.name);
    blocks.forEach(block => {
        this.Composite.add(this.engine.world, block);
    })

    // Add outside wall
    const landscapeWalls = [
      /*left*/{ coord: { x: -1.08, y: 0}, width: 1, height: 6, collider: false},
      /*right*/{ coord: { x: 13.1, y: 0}, width: 1, height: 6, collider: false},
      /*top*/{ coord: { x: 0, y: -1.1}, width: 12, height: 1, collider: false},
      /*bottom*/{ coord: { x: 0, y: 6.01}, width: 11, height: 1, collider: false}
    ];
    const portraitWalls = [
      /*left*/{ coord: { x: -1.1, y: 0}, width: 1, height: 12, collider: false},
      /*right*/{ coord: { x: 6.02, y: 0}, width: 1, height: 12, collider: false},
      /*top*/{ coord: { x: 0, y: -1.1}, width: 6, height: 1, collider: false},
      /*bottom*/{ coord: { x: 1, y: 12.1}, width: 5, height: 1, collider: false}
    ]
    const walls = this.format.name === 'landscape' ? landscapeWalls : portraitWalls;
    walls.forEach(wall => {
      this.Composite.add(this.engine.world, new Box(wall, this.tileSize).init());
    })

    if(this.colliders) {
      this.detectCollision();
    }

    /* TODO : For testing */
    this.balls = [];
    for (let i = 0; i < 30; i++) {
      const ball = this.Bodies.circle(30, 5, 10, {
        friction: 0.00001
      });
      ball.gravityDir = 'down';
      this.Composite.add(this.engine.world, ball);
      this.balls.push(ball);
    }

    // Change gravity of balls who have touch the bottom collider
    if(this.engine){
      this.Events.on(this.engine, 'beforeUpdate', () => {
        const gravity = this.engine.gravity;
        this.balls.forEach(ball => {
          if(ball.gravityDir === "up" ){
            this.Body.applyForce(ball, ball.position, {
              x: 0,
              y: -gravity.y * gravity.scale / 2
            });
          }
        })
      });
    }

  }

  /**
   * @param {String} format - format's name
   * @returns {[Matter.Bodies]}
   */
  loadMap(format) {
    const map = format === 'landscape' ? landscapeMap : portraitMap;
    const bodies = [];
    for (let row = 0; row < map.length; row++) {
      for (let column = 0; column < map[row].length; column++) {
        if(map[row][column] !== '.') {
          const block = codeToBlock(map[row][column], row, column);
          switch (block.type) {
            case 'slope':
              bodies.push(new Slope(block, this.tileSize).init());
              break;
            case 'box':
              // eslint-disable-next-line no-case-declarations
              const box = new Box(block, this.tileSize).init()
              bodies.push(box);
              if(block.collider) {
                box.gravityDir = block.gravity;
                this.colliders.push(box)
              }
              break;
          }
        }
      }
    }
    return bodies;
  }

  detectCollision() {
    this.Events.on(this.engine, 'collisionStart', (event) => {
      const pairs = event.pairs;
      for (let i = 0, j = pairs.length; i !== j; ++i) {
        const pair = pairs[i];

        if (pair.bodyA === this.colliders[1]) {
          pair.bodyB.gravityDir = "up";
        } else if (pair.bodyA === this.colliders[0]) {
          pair.bodyB.gravityDir = "down";
        }
      }
    });
  }

  clear(){
    if(!this.engine || !this.render || !this.runner) return;
    this.Engine.clear(this.engine);
    this.Render.stop(this.render);
    this.Runner.stop(this.runner);
  }
}
