import * as Matter from 'matter-js';
import { pickRandomColor } from '../../utils/color';

export default class Box {
  constructor (config, tileSize) {
    this.config = config;
    this.tileSize = tileSize;
  }
  init() {
    const color = this.config.collider ? 'transparent' : pickRandomColor();
    const bodies = Matter.Bodies.rectangle(
      0, 0,
      this.config.width * this.tileSize, this.config.height * this.tileSize,
      {
        isSensor: this.config.collider,
        isStatic: true,
        render: {
          strokeStyle: color,
          lineWidth: 1,
          fillStyle: color,
        }
      }
    )
    Matter.Body.setPosition(
      bodies,
      {
        x: Math.abs(bodies.bounds.min.x) + this.config.coord.x * this.tileSize,
        y: Math.abs(bodies.bounds.min.y) + this.config.coord.y * this.tileSize,
      }
    )
    return bodies;
  }
}
