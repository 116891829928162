import * as Matter from 'matter-js';
import { pickRandomColor } from '../../utils/color'

export default class Slope {
  constructor (config, tileSize) {
    this.config = config;
    this.tileSize = tileSize;
  }
  init() {
    let vertices = [];
    if (this.config.direction === 'left' && !this.config.inverted) {
      vertices = [
        {x: 0, y: 0},
        {x: this.tileSize * this.config.size, y: this.tileSize},
        {x: 0, y: this.tileSize},
      ]
    } else if (this.config.direction === 'right' && !this.config.inverted) {
      vertices = [
        {x: this.tileSize * this.config.size, y: 0},
        {x: this.tileSize * this.config.size, y: this.tileSize},
        {x: 0, y: this.tileSize},
      ]
    } else if (this.config.direction === 'left' && this.config.inverted) {
      vertices = [
        {x: 0 , y: 0},
        {x: this.tileSize * this.config.size, y: 0},
        {x: 0, y: this.tileSize},
      ]
    } else if (this.config.direction === 'right' && this.config.inverted) {
      vertices = [
        {x: 0 , y: 0},
        {x: this.tileSize * this.config.size, y: 0},
        {x: this.tileSize * this.config.size, y: this.tileSize},
      ]
    }

    const color = pickRandomColor();
    const bodies = Matter.Bodies.fromVertices(0, 0, vertices, {
      isStatic: true,
      render: {
        strokeStyle: color,
        lineWidth: 1,
        fillStyle: color,
      }
    });
    Matter.Body.setPosition(
      bodies,
      {
        x: Math.abs(bodies.bounds.min.x) + this.config.coord.x * this.tileSize,
        y: Math.abs(bodies.bounds.min.y) + this.config.coord.y * this.tileSize,
      }
    )
    return bodies;
  }
}
